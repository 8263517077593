import * as React from 'react';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import HomeHeader from '../../components/Header/HomeHeader';
import NotFound from '../../modules/about/NotFound';

const NotFoundPage = () => {
  return (
    <Layout>
      <HomeHeader />
      <NotFound />
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
