import React from "react";
import { NotFoundWrapper } from "./Styled";

const NotFound = () => {

  return (
    <NotFoundWrapper>
      <h1>404</h1>
      <p>Looks like a bad link</p>
    </NotFoundWrapper>
  )
}

export default NotFound;